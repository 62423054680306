<template lang="pug">
.project
  CommonHeader(:data="headerData")
  .service-company.main
    h3 企业服务
    .service-company-inner
      ul
        li(v-for="(item, index) in new Array(6)" :key="index")
          .title ERP开发
          .content 小枝科技深耕ERP多年，为企业决策层及员工提供决策运行手段的管理手段。
  .service-follow
  .case
    .case-section-bg
    .case-inner
      .case-head
        .case-title 客户案例
        .case-content 小枝科技的解决方案是他们的认真思考后的结果
      .case-swiper
        swiper.swiper(:options="swiperOptions" ref="swiper")
          swiper-slide(v-for="(item, index) in new Array(4)" :key="index")
            .swiper-slide
              .swiper-slide-icon
                img(src="@/assets/images/logo.png")
              .swiper-slide-inner
                .swiper-slide-inner-title 中国石油
                .swiper-slide-inner-subtitle 疫情监测管理系统
                .swiper-slide-inner-subtitle 小枝科技为中石油东直门大厦及六铺炕办公区在疫情期间在人员进出，体温监测，智慧食堂等方面提供有力支持。
        .swiper-btn
          .swiper-prev(@click="handleSwiperSlideNext('slideNext')") ←
          .swiper-next(@click="handleSwiperSlideNext('slidePrev')") →
  .service-expert
    .service-expert-title 专家服务
    .service-expert-content(v-html="'如果您有技术开发需求，氪体提交信息, <br/> 如果您有技术开发需求，氪体提交信息，进行咨询报价，我们商务人员会尽快联系您。'")
    .service-expert-btn 立即联系
</template>
<script>
// import Vue from "vue";
import CommonHeader from '@/components/common-header.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'


export default {
  components: {
    CommonHeader,
    swiper,
    swiperSlide
  },
  data() {
    return {
      headerData: {
        title: "专注数据化转型的软件服务",
        content: "小枝科技始终专注于提供全方位的IT技术服务及行业数字化转型解决方案,包括：软件开发、移动端应用开发、人工智能、物联网应用、IT咨询服务产品质量测试运维等。",
        backgroundImage: require('@/assets/images/industry_bg.png')
      }, 
      swiperOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 20,
      }
    }
  },
  methods: {
    handleSwiperSlideNext(fnName) {
      this.$refs.swiper.swiper[fnName]()
    },
  }
}
</script>
<style lang="stylus" scoped>
ul,li
  margin 0
  padding 0
li
  list-style none
.project
  background-color #F1F4F8
.service-follow
  height 505px
  background url('~@/assets/images/about_service_flow.png') center no-repeat
  background-size 1280px 505px
.service-company
  h3
    font-size 26px
    color #333
    text-align center
    margin 50px 0 40px
  &-inner
    ul
      width 1240px
      margin-bottom -40px
    li
      display inline-block
      vertical-align top
      width 352px
      margin-right 60px
      margin-bottom 40px
      background #fff
      padding 22px 15px 24px
      .title
        font-size 18px
        color #333
        font-weight bold
        margin-bottom 12px
      .content
        font-size 14px
        color #666
        line-height 1.5
.case
  height 500px
  position relative
  background-color #1D2538
  &-inner
    padding-left 42%
    position relative
  &-section-bg
    width 42%
    position absolute
    height 100%
    top 0
    left 0
    background url("~@/assets/images/industry_bg.png") center no-repeat
    background-size cover
  &-head
    padding-top 100px
    margin-left 55px
  &-title
    font-size 30px
    color: #fff
    margin-bottom 14px
  &-content
    font-size 14px
    color: #fff
  &-swiper
    margin-top: 50px;
    margin-left: -240px;
    overflow: hidden;
    .swiper-btn
      display flex
      color #fff
      margin-top 10px
      margin-left 280px
      div
        margin 0 10px;
        padding 5px 5px
        cursor pointer
        user-select none
        font-size 18px;
        &:hover
          opacity 0.8
          transition .2s color

.swiper
  width 1560px
  max-height: 600px;
  min-height: 180px;
  &-slide-inner
    padding-left 116px
    padding-top 26px
    &-title
      color #333
      font-size 18px
      margin-bottom 6px
    &-subtitle
      color #666
      font-size 14px
      margin-bottom 15px
    &-content
      color #333
      font-size 14px
.swiper_box {
  .swiper-slide {
    width: 600px;
    height: 400px;
    // border: 1px solid rgba(255, 255, 255,0);
    box-shadow: 0 2px 6px rgb(133, 133, 133);
}
.swiper-slide>img {
    width: 100%;
    height: 100%;
}
.swiper-container {
    margin-top: 20px;
    width: 100%;
    height: 520px;
    // margin-bottom: 53px;
    overflow: visible !important;
    margin-bottom: -30px;
  }
 
  .swiper-container .swiper-wrapper .swiper-slide {
    width: 1200px;
    border-radius: 2px;
    height: 480px;
  }
 
  .swiper-container .swiper-wrapper .swiper-slide img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
 
  }
 
   .swiper-slide.swiper-slide-active:before {
    opacity: 0;
   }
   .swiper-container .swiper-pagination {
    bottom:60px;
  }
 
  .swiper-pagination .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: rgba(0,0,0,0.6);
  }
 
  .swiper-pagination .swiper-pagination-bullet-active {
    background: #fff;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    color: rgb(171, 149, 230);
  }
}
.swiper-slide
  height 178px
  background-color #fff
  position relative
  &-icon
    position absolute
    width 56px;
    height 50px
    overflow hidden
    top 30px
    left 30px
    border-radius 50%
    img {
      width 100%
      height 100%
    }

.service-expert
  text-align center
  padding-bottom 60px;
  background url("~@/assets/images/service-expert-bg.png") center no-repeat
  background-size cover
  &-title
    font-size 26px
    color #333
    padding 60px 0 20px
    font-weight bold
  &-content
    font-size 14px
    color #666
    line-height 2.5
    padding-bottom 40px
  &-btn
    width 114px
    height 40px
    fot-size 18px;
    display inline-flex
    align-items center
    justify-content center
    background-color #3AD682
    color #fff
    user-select none
    cursor pointer
</style>