<template lang="pug">
.banner(:style="{ backgroundImage: `url(${data.backgroundImage})` }")
  .banner-inner.main
    h2 {{data.title}}
    p.desc {{data.content}}
</template>

<script>
export default {
  name: "Common-Header",
  props: {
    data: {
      type: Object,
      default: () => ({
        title: '',
        content: '',
        backgroundImage: '',
      }),
    }
  },
  methods: {
  },
}
</script>
<style lang="stylus" scoped>
.banner
  height 364px
  background-position center
  background-origin center
  background-repeat no-repeat
  background-size cover
  color #fff
  h2
    margin 0
    font-size 40px
    line-height 56px
    padding-top 105px
    margin-bottom 20px
  .desc
    width 480px
    font-size 16px
</style>